<template>
    <v-main class="pr-2 pl-2" style="background-color: #e0e0e0;">
        <v-toolbar class="ma-2" style="border-radius: 30px;">
            <v-btn icon link to="/">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer/>
            <v-toolbar-title>
                Configurar Módulo Fiscal
            </v-toolbar-title>
            <v-spacer/>
        </v-toolbar>
        <v-card elevation="2" class="ma-1">
            <v-card-text class="text-center" :style="`height: ${$vuetify.breakpoint.height - 140}px;`">
                <h1>Bem-vindo as configuração do Módulo Fiscal</h1>
                <br>
                <br>
                <h3>
                    Clique em "iniciar" para habilitar o módulo fiscal em seu sistema.
                    <br>
                    Em seguida você poderá iniciar as configurações necessárias para emissão de documentos fiscais.
                </h3>
                <v-btn
                    @click="setup"
                    x-large
                    class="mt-4"
                    color="success"
                    :loading="loading"
                >Iniciar</v-btn>
            </v-card-text>
        </v-card>
    </v-main>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    name: 'SetupNotas',

    data: () => ({
        loading: false,
    }),

    methods: {
        ...mapMutations({
            setConfig: 'setConfig',
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        setup() {
            this.loading = true;
            this.$http.post('fiscal/setup').then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.setConfig({ option: 'fiscal', value: resp.data.data });
                this.setDashboardInitialized(false);
                this.notify('Inicializado com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>
